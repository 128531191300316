import React, { Component } from "react";
import "./portfolio-pages.scss";

class InformationMediaryCorp extends Component {
  render() {
    return (
      <React.Fragment>
        <main className="pf-page">
          <div className="pf-info">
            <h2>Information Mediary Corp</h2>
            <img src={require('./../../../assets/image/imc_logo.png')} alt="IMC logo image" className="pf-logo" />
            <a className="pf-btn" rel="noopener noreferrer" target="_blank" href="https://www.informationmediary.com/">Visit Site</a>
            </div>
          <div className="left-panel">
            <p className="pf-copy">IMC is the global leader in smart medication adherence solutions, having supplied over a million units to the clinical trials and research markets. IMC manufactures iOS and Android NFC-certified Med-ic Smart Blister Packages, eCAP Smart Medication Bottles and the temperature-aware Med-ic Smart Syringe Pack.</p>
            <p className="pf-copy">Assembled and integrating user-facing and front-end elements to build company web app. Implemented new design specs for version 2/3 of the app. Worked with testers and QA team to deliver an end product that passed all validations.</p>
            <p className="pf-copy">The technologies were: React, Redux, JavaScript, HTML, SASS</p>
          </div>

          <div className="right-panel">
            <img src={require('./../../../assets/image/imc_1.png')} alt="imc" className="pf-feature-img" />
            <img src={require('./../../../assets/image/imc_2.png')} alt="imc" className="pf-img" />        
          </div>
        </main>
    </React.Fragment>
    );
  }
}
export default InformationMediaryCorp;
